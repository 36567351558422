import React from 'react';

import magentoHostingImage1 from 'assets/images/pages/diensten_magentohosting/1.jpg';
import magentoHostingImage2 from 'assets/images/pages/diensten_magentohosting/2.jpg';

import rocketIcon from 'assets/images/icons/rocket.svg';
import euroIcon from 'assets/images/icons/euro.svg';
import lockIcon from 'assets/images/icons/lock.svg';
import serverIcon from 'assets/images/icons/server.svg';
import chatIcon from 'assets/images/icons/chat.svg';
import heartIcon from 'assets/images/icons/heart.svg';

import Title from 'blocks/Title';
import Grid from 'blocks/Grid';
import Image from 'blocks/Image';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Usp from 'components/Usp';

const usp = [
  {
    title: 'Obsessie voor snelheid',
    info:
      'Bij onze hosting ligt de focus altijd op de snelheid van jouw webwinkel.',
    icon: rocketIcon,
  },
  {
    title: 'Goede prijs',
    info:
      'Stabiele, snelle en betrouwbare hosting voor misschien wel de scherpste prijs in de markt!',
    icon: euroIcon,
  },
  {
    title: 'Betrouwbaar',
    info:
      'Door onze redundante hosting kun je rekenen op optimale bereikbaarheid.',
    icon: lockIcon,
  },
  {
    title: 'Gratis development server',
    info:
      'Gratis development server voor Batao klanten bij aanschaf van een Magento Hosting pakket.',
    icon: serverIcon,
  },
  {
    title: 'Daan hét point of contact',
    info:
      'Met Daan hebben we iemand in huis die werkelijk een obsessie heeft voor snelheid.',
    icon: chatIcon,
  },
  {
    title: 'Voor onze klanten',
    info:
      'Wij willen er zeker van zijn dat onze klanten terecht kunnen bij een goede hostingpartij.',
    icon: heartIcon,
  },
];

const DienstenOnderhoudHosting = () => (
  <Layout>
    <Seo image={{ src: magentoHostingImage1 }} />

    <Title
      image={{ src: magentoHostingImage1 }}
      alt="Magento hosting door Batao"
      subtitle="Stabiel, snel en betrouwbaar"
      title="Magento hosting door Batao"
    >
      Een nieuwe dienst van Batao: onze eigen hosting! Wanneer we een Magento
      webshop hebben opgeleverd, willen we er zeker van zijn dat onze klanten
      terecht kunnen bij een goede hostingpartij.
    </Title>

    <Section>
      <Image
        image={{ src: magentoHostingImage2 }}
        alt="Focus op de snelheid van jouw webwinkel"
      >
        <Heading size="xl" as="h2">
          Focus op de snelheid van jouw webwinkel
        </Heading>

        <Text>
          Bij onze hosting ligt de focus altijd op de snelheid van jouw
          webwinkel. Met Daan hebben we iemand in huis die werkelijk een
          obsessie heeft voor die snelheid. Zijn regel dat elke seconde
          vertraging 10% van jouw omzet kost, is iets wat hij binnen de gehele
          organisatie keer op keer blijft benadrukken. We vinden het dus
          superbelangrijk dat jouw webwinkel stabiel, snel en betrouwbaar is.
          Iedere dag zijn we daarom bezig met innoveren om onze diensten steeds
          weer beter te maken. En dat voor misschien wel de scherpste prijs in
          de markt!
        </Text>
      </Image>
    </Section>

    <Section>
      <Grid>
        {usp.map((item, key) => (
          <Usp key={key} icon={item.icon} title={item.title}>
            <Heading color="primary-light" size="sm">
              {item.title}
            </Heading>

            <Text>{item.info}</Text>
          </Usp>
        ))}
      </Grid>
    </Section>

    <ContactCta />
  </Layout>
);

export default DienstenOnderhoudHosting;
